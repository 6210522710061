import React, { useState, useEffect } from "react";
import "./styles.css";

function App() {
  const [grid, setGrid] = useState(createEmptyGrid());
  const [gameOver, setGameOver] = useState(false);
  const [gameWon, setGameWon] = useState(false);

  function createEmptyGrid() {
    return Array(4)
      .fill()
      .map(() => Array(4).fill(0));
  }

  useEffect(() => {
    checkGameOver();
  }, [grid]);

  function addNumber() {
    let newGrid = [...grid];
    let availableSpots = [];
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        if (newGrid[i][j] === 0) {
          availableSpots.push({ x: i, y: j });
        }
      }
    }
    if (availableSpots.length > 0) {
      let randomSpot =
        availableSpots[Math.floor(Math.random() * availableSpots.length)];
      newGrid[randomSpot.x][randomSpot.y] = Math.random() > 0.5 ? 2 : 4;
      setGrid(newGrid);
    }
  }

  function moveUp() {
    let newGrid = [...grid];
    let moved = false;
    for (let j = 0; j < 4; j++) {
      for (let i = 1; i < 4; i++) {
        if (newGrid[i][j] !== 0) {
          let k = i;
          while (k > 0 && newGrid[k - 1][j] === 0) {
            newGrid[k - 1][j] = newGrid[k][j];
            newGrid[k][j] = 0;
            k--;
            moved = true;
          }
          if (k > 0 && newGrid[k - 1][j] === newGrid[k][j]) {
            newGrid[k - 1][j] *= 2;
            newGrid[k][j] = 0;
            moved = true;
          }
        }
      }
    }
    if (moved) {
      addNumber();
    }
    setGrid(newGrid);
  }

  function moveDown() {
    let newGrid = [...grid];
    let moved = false;
    for (let j = 0; j < 4; j++) {
      for (let i = 2; i >= 0; i--) {
        if (newGrid[i][j] !== 0) {
          let k = i;
          while (k < 3 && newGrid[k + 1][j] === 0) {
            newGrid[k + 1][j] = newGrid[k][j];
            newGrid[k][j] = 0;
            k++;
            moved = true;
          }
          if (k < 3 && newGrid[k + 1][j] === newGrid[k][j]) {
            newGrid[k + 1][j] *= 2;
            newGrid[k][j] = 0;
            moved = true;
          }
        }
      }
    }
    if (moved) {
      addNumber();
    }
    setGrid(newGrid);
  }

  function moveRight() {
    let newGrid = [...grid];
    let moved = false;
    for (let i = 0; i < 4; i++) {
      for (let j = 2; j >= 0; j--) {
        if (newGrid[i][j] !== 0) {
          let k = j;
          while (k < 3 && newGrid[i][k + 1] === 0) {
            newGrid[i][k + 1] = newGrid[i][k];
            newGrid[i][k] = 0;
            k++;
            moved = true;
          }
          if (k < 3 && newGrid[i][k + 1] === newGrid[i][k]) {
            newGrid[i][k + 1] *= 2;
            newGrid[i][k] = 0;
            moved = true;
          }
        }
      }
    }
    if (moved) {
      addNumber();
    }
    setGrid(newGrid);
  }

  function moveLeft() {
    let newGrid = [...grid];
    let moved = false;
    for (let i = 0; i < 4; i++) {
      for (let j = 1; j < 4; j++) {
        if (newGrid[i][j] !== 0) {
          let k = j;
          while (k > 0 && newGrid[i][k - 1] === 0) {
            newGrid[i][k - 1] = newGrid[i][k];
            newGrid[i][k] = 0;
            k--;
            moved = true;
          }
          if (k > 0 && newGrid[i][k - 1] === newGrid[i][k]) {
            newGrid[i][k - 1] *= 2;
            newGrid[i][k] = 0;
            moved = true;
          }
        }
      }
    }
    if (moved) {
      addNumber();
    }
    setGrid(newGrid);
  }

  function checkGameOver() {
    let emptyCells = grid.flat().filter((value) => value === 0).length;
    let possibleMoves = false;

    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < 4; j++) {
        if (i > 0 && grid[i][j] === grid[i - 1][j]) {
          possibleMoves = true;
        }
        if (i < 3 && grid[i][j] === grid[i + 1][j]) {
          possibleMoves = true;
        }
        if (j > 0 && grid[i][j] === grid[i][j - 1]) {
          possibleMoves = true;
        }
        if (j < 3 && grid[i][j] === grid[i][j + 1]) {
          possibleMoves = true;
        }
      }
    }

    if (emptyCells === 0 && !possibleMoves) {
      setGameOver(true);
    }
    if (grid.flat().includes(2048)) {
      setGameWon(true);
    }
  }

  function resetGame() {
    setGrid(createEmptyGrid());
    setGameOver(false);
    setGameWon(false);
  }

  return (
    <div className="App">
      <h2>2048</h2>
      {gameOver && <div className="message">Game Over!</div>}
      {gameWon && <div className="message">You Win!</div>}
      {grid.map((row, i) => (
        <div key={i} style={{ display: "flex" }}>
          {row.map((value, j) => (
            <div
              key={j}
              className="cell"
              data-value={value !== 0 ? value : null}
            >
              {value !== 0 ? value : ""}
            </div>
          ))}
        </div>
      ))}
      <div className="buttons">
        <div className="buttons-upper">
          <button onClick={moveUp}>
            <i class="fa-solid fa-arrow-up"></i>
          </button>
        </div>
        <div className="buttons-lower">
          <button onClick={moveLeft}>
            <i class="fa-solid fa-arrow-left"></i>
          </button>
          <button onClick={moveDown}>
            <i class="fa-solid fa-arrow-down"></i>
          </button>
          <button onClick={moveRight}>
            <i class="fa-solid fa-arrow-right"></i>
          </button>
        </div>
        {grid.flat().every((value) => value === 0) ? (
          <button className="start-btn" onClick={addNumber}>
            Start Game
          </button>
        ) : (
          <button className="reset-btn" onClick={resetGame}>
            Reset Game
          </button>
        )}
      </div>
    </div>
  );
}

export default App;
